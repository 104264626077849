import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import '../../../../../components/carousels/carousel.css';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; // Import useLocation
import davidSmith from '../../../../../images/david.webp';
import user from '../../../../../images/user-thumbnail.jpg';
import randyBoldyga from '../../../../../images/randy-boldyga.svg';
import balaji from '../../../../../images/balaji.webp';
import michael from '../../../../../images/michael.webp';
import Biju from '../../../../../images/solutions/Biju.jpg';
import chuck from '../../../../../images/chuck-east.svg';
import mark from '../../../../../images/solutions/mark_crawford_interplai.webp';
import vic from '../../../../../images/solutions/Vic-LeBouthillier.webp';
import testimonialVideo from "../../../../../videos/client-testimonial.mp4";
import FrankTestimonialVideoPlayer from '../../../../../components/videos/frank-testimonial';
import JimTormey from '../../../../../images/jim-tormey.webp';

// Testimonials array
const testimonials = [
    {
        type: "text",
        imgSrc: davidSmith,
        name: "David Smith, CEO Tynax, Inc",
        text: "I have been very impressed by ACube's work and would highly recommend them.",
        industry: "education"
    },
    {
        type: "text",
        imgSrc: randyBoldyga,
        name: "Randy Boldyga, CEO/President RxNT",
        text: "Acube provided excellent service and demonstrated strong technical capability. I would recommend.",
        industry: "healthcare"
    },
    {
        type: "video",
        videoSrc: testimonialVideo,
        name: "James Shepherd, Founder, Frank Food Safety",
        industry: "hospitality"
    },
    {
        type: "text",
        imgSrc: Biju,
        name: "Biju Kalissery, CTO and Co-founder, Hopscotch",
        text: "At JoinHopscotch.com, we've had an outstanding experience working with Acubetech Solutions Pvt Ltd. Their technical expertise and dedication have helped us efficiently meet our goals. From full-stack development to cloud-based solutions, their work has been nothing short of exceptional. Their team is responsive, reliable, and consistently delivers high-quality solutions tailored to our needs.",
        industry: "healthcare"
    },
    {
        type: "text",
        imgSrc: vic,
        name: "Vic LeBouthillier, Product Manager and Board Chairman",
        text: "Their technical expertise and innovative approach have greatly enhanced our projects. Whether it's full-stack development or seamless cloud integration, Acubetech consistently delivers high-quality solutions tailored to our needs.",
        industry: "healthcare"
    },
    {
        type: "text",
        imgSrc: balaji,
        name: "Balaji Shyamkumar, Head of Engineering",
        text: "We are thrilled with the exceptional partnership we’ve had with Acubetech Solutions Pvt Ltd. Their deep technical expertise and innovative solutions have played a key role in advancing our projects.",
        industry: "fintech"
    },
    {
        type: "text",
        imgSrc: chuck,
        name: "Chuck East, Technical Product Manager, Openn",
        text: "Thank you for everyone’s willingness to pull together around the shifting priorities. The hard work, extra hours, and deep product knowledge are examples of how the teams go the extra mile.",
        industry: "realEstate"
    },
    {
        type: "text",
        imgSrc: mark,
        name: "Mark A. Crawford, CEO & CTO, interplai",
        text: "Our collaboration with Acubetech Solutions Pvt Ltd has been remarkable. Their expertise in full-stack development, cloud solutions, and advanced integration has greatly contributed to the success of our projects.",
        industry: "gpsTracking"
    },
    {
        type: "text",
        imgSrc: michael,
        name: "Micheal Smith, COO, SVHS",
        text: "I highly recommend Acubetech as a development partner. Their team of certified AWS professionals and architects demonstrated exceptional expertise in setting up a robust and scalable AWS infrastructure for us. The entire process was smooth, and their deep knowledge ensured the job was done efficiently and to the highest standards.",
        industry: "education"
    },
    {
        type: "text",
        imgSrc: user,
        name: "Uri Oren, Smart Sys - Logistic App",
        text: "Acube exceeded our expectations with their expertise in IOS app development. Their team was highly professional, delivering a scalable solution that perfectly met our needs. I highly recommend them for any technical project.",
        industry: "gpsTracking"
    },
    {
        type: "text",
        imgSrc: JimTormey,
        name: "Jim Tormey, Chief Executive Officer, Sentry Road Training and Development",
        text: "My name is Jim, and I run a learning management software business on Moodle. A few months ago, we needed a part-time developer for projects like platform upgrades and automated reports. The Acube Technologies team has been phenomenal—responsive, communicative, and reliable. They followed through on every commitment and kept me informed of any challenges. I’m a big fan and look forward to working with them in the future.",
        industry: "healthcare"
    }
];

// URL Routing array
// URL Routing array with added titles
export const urlRouting = [
    { pathname: "/hospitality", pageName: "hospitality", title: "Hospitality" },
    { pathname: "/healthcare", pageName: "healthcare", title: "Healthcare" },
    { pathname: "/education", pageName: "education", title: "Education" },
    { pathname: "/real-estate", pageName: "realEstate", title: "Real Estate" },
    { pathname: "/fintech", pageName: "fintech", title: "FinTech" },
    { pathname: "/gps-tracking", pageName: "gpsTracking", title: "GPS Tracking" },
    { pathname: "/e-commerce", pageName: "ecommerce", title: "E-Commerce" },
    { pathname: "/erp-solutions", pageName: "erpSolutions", title: "ERP Solutions" },
];


const SuccessStoriesTestimonial = () => {
    const location = useLocation();
    const [isReady, setIsReady] = useState(false);

    // Find the current route based on the pathname
    const currentRoute = urlRouting.find(route => route.pathname === location.pathname);
    const currentIndustry = currentRoute ? currentRoute.pageName : "defaultIndustry"; // Fallback to default
    const currentTitle = currentRoute ? currentRoute.title : "Industry"; // Use the title for display

    // Filter testimonials based on the current industry (pageName)
    let filteredTestimonials = testimonials.filter(
        (testimonial) => testimonial.industry === currentIndustry
    );

    // If the path is "/e-commerce", show all testimonials
    if (currentIndustry === 'ecommerce' || currentIndustry === 'erpSolutions') {
        filteredTestimonials = testimonials;
    }


    useEffect(() => {
        setIsReady(true); // Set component as ready after mount

        return () => window.removeEventListener('resize', () => setIsReady(true));

    }, []);

    if (!isReady) return null; // Prevent loading carousel until ready


    const successstorycarousel = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: filteredTestimonials.length <= 2 ? filteredTestimonials.length : 3, // Show 1 or 2 slides, otherwise show 3
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: filteredTestimonials.length === 1 ? 1 : 2, // For 1 or 2 items show respective slides
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1, // On smaller screens show 1 slide
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1, // On extra small screens, always show 1 slide
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                }
            }
        ]
    };

    return (
        <section className="success-stories testimonial-section px-0">
            <Container fluid>
                <h1 className="title-text text-uppercase text-center text-white mb-3 mb-lg-5">
                    What {currentTitle || "Industry"} Leaders Are Saying About ACube
                </h1>
                {filteredTestimonials.length > 0 ? (
                    <Slider {...successstorycarousel} className="testimonial-carousel">
                        {filteredTestimonials.map((testimonial, index) => (
                            <div
                                className={`pt-50 position-relative w-auto mx-auto ${filteredTestimonials.length === 1 ? "single-slide text-center" : ""
                                    }`}
                                key={index}
                            >
                                {testimonial.type === "text" ? (
                                    <>
                                        {testimonial.imgSrc && (
                                            <div className="testimonial-img-div">
                                                <img
                                                    className="mb-3 testimonial-img"
                                                    loading="lazy"
                                                    src={testimonial.imgSrc}
                                                    alt={testimonial.name}
                                                />
                                            </div>
                                        )}
                                        <div className="bg-light p-4 pb-5 mx-3 rounded-4 card-height h-100 leaders-card">
                                            <div className="content-block">
                                                <h4 className="mb-2 mb-lg-3 fw-bold text-primary">
                                                    {testimonial.name}
                                                </h4>
                                                <h6 className="mb-4 text-primary">
                                                    {testimonial.text}
                                                </h6>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="bg-light p-4 pb-5 mx-3 rounded-4 d-flex justify-content-center card-height">
                                        <div className="content-block text-center mt-0">
                                            <div className="video-testimonial mb-4">
                                                <FrankTestimonialVideoPlayer />
                                                <h4 className="mt-3 text-primary fw-bold text-start">
                                                    {testimonial.name}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <p className="text-center text-white">No testimonials available.</p>
                )}
            </Container>
        </section>
    );
};


export default SuccessStoriesTestimonial;
