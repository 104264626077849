// carousel-config.js

import HospitalityCarousel from "../pages/solutions/subpages/components/carousels/hospitality-carousel";
import HealthcareCarousel from "../pages/solutions/subpages/components/carousels/healthcare-carousel";
import EducationCarousel from "../pages/solutions/subpages/components/carousels/education-carousel";
import EcommerceCarousel from "../pages/solutions/subpages/components/carousels/ecommerce-carousel";
import RealEstateCarousel from "../pages/solutions/subpages/components/carousels/real-estate-carousel";
import FintechCarousel from "../pages/solutions/subpages/components/carousels/fintech-carousel";
import GPSTrackingCarousel from "../pages/solutions/subpages/components/carousels/gps-tracking-carousel";
import ERPSolutionsCarousel from "../pages/solutions/subpages/components/carousels/erp-solutions-carousel";

export const carouselConfig = {
    hospitality: [<HospitalityCarousel key="hospitality" />],
    healthcare: [<HealthcareCarousel key="healthcare" />],
    education: [<EducationCarousel key="education" />],
    ecommerce: [<EcommerceCarousel key="e-commerce" />],
    realEstate: [<RealEstateCarousel key="realEstate" />],
    fintech: [<FintechCarousel key="fintech" />],
    gpsTracking: [<GPSTrackingCarousel key="gpsTracking" />],
    erpSolutions: [<ERPSolutionsCarousel key="erpSolutions" />]
};
