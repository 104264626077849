import React from "react";
import { InlineWidget } from "react-calendly";

const CalendlyEmbed = () => {
    // Determine the height based on the screen width
    const getHeight = () => {
        if (window.innerWidth <= 480) {
            return "1200px"; // Height for mobile devices
        } else if (window.innerWidth <= 1024) {
            return "1200px"; // Height for tablets
        }
        return "700px"; // Default height for desktops
    };

    return (
        <div className="calendly-container">
            <InlineWidget
                url="https://calendly.com/acube/discovery-call?month=2024-07"
                styles={{ height: getHeight() }}
            />
        </div>
    );
};

export default CalendlyEmbed;
