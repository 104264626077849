import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import ScrollToTopNav from './components/scroll-to-top/scroll-to-top-nav';
import { ScrollProvider } from './context/scroll-context';
import { AppProvider } from './context/app-context';
import { Helmet } from 'react-helmet-async';
import LoadingScreen from './components/loader/loading-screen';
import ErrorPageLayout from './layouts/error-layout';
import routeMetadata from './route-metadata';
import PrivacyPolicy from './pages/privacy-policy';
import SolutionsBaseLayout from './pages/solutions/subpages/base-layout';

const HomePage = lazy(() => import('./pages/home/home'));
const DefaultLayout = lazy(() => import('./layouts/default-layout'));
const ContactUs = lazy(() => import('./pages/contact-us/contact-us'));
const Portfolio = lazy(() => import('./pages/portfolio/portfolio'));
const BaseLayout = lazy(() => import('./pages/services/subpages/base-layout'));
const PortfolioBaseLayout = lazy(() => import('./pages/portfolio/subpages/base-layout'));
const HireTeamBaseLayout = lazy(() => import('./pages/hire-team/subpages/base-layout'));
const NotFound = lazy(() => import('./pages/not-found'));

function App() {

  const location = useLocation();
  const { pathname } = location;
  // const websiteName = "ACUBE USA LLC"
  // const currentPath = location.pathname;

  // Determine the page title based on the current pathname
  const { title, description, keywords } = routeMetadata[pathname] || { description: '', keywords: '' };
  const metadata = routeMetadata[pathname] || routeMetadata['/'];

  const canonicalUrl = pathname ? `https://acubetechnologies.com${pathname}` : "https://acubetechnologies.com";

  // ReactGA.initialize(process.env.REACT_APP_GTAG_ID);

  // useEffect(() => {
  //   // Track page views
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);


  useEffect(() => {
    // Google Tag Manager (GTM) integration
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-WTCN52MF');
  }, []);

  useEffect(() => {
    // Google Analytics (gtag) integration
    const gtagScript = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=AW-16685554601`;
    document.head.appendChild(gtagScript);

    gtagScript.onload = () => {
      if (typeof window.gtag === 'function') {
        window.gtag('config', 'AW-16685554601', {
          page_path: location.pathname + location.search,
        });
      }
    };

    // Return a cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(gtagScript);
    };
  }, [location]);

  return (


    <ScrollProvider>
      <AppProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          {/* Twitter Meta Tags */}
          <meta name="twitter:card" content={metadata.twitterCard || 'summary_large_image'} />
          <meta name="twitter:title" content={metadata.title} />
          <meta name="twitter:description" content={metadata.description} />

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content={metadata.title} />
          <meta property="og:description" content={metadata.description} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />

          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <ScrollToTopNav />
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route exact path="/" element={<HomePage />} />

              {/* Service Pages */}
              <Route path="/artificial-intelligence" element={<BaseLayout pageName="ai" />} />
              <Route path="/web-app" element={<BaseLayout pageName="web-development" />} />
              <Route path="/blockchain-app" element={<BaseLayout pageName="blockchain-app" />} />
              <Route path="/cloud-strategy" element={<BaseLayout pageName="cloud" />} />
              <Route path="/robotic-process-automation" element={<BaseLayout pageName="robotic-automation" />} />
              <Route path="/devops" element={<BaseLayout pageName="devops" />} />
              <Route path="/uxui-services" element={<BaseLayout pageName="ux-ui" />} />
              <Route path="/mobile-app" element={<BaseLayout pageName="mobile-app" />} />
              <Route path="/software-testing" element={<BaseLayout pageName="software-testing" />} />
              <Route path="/analytics" element={<BaseLayout pageName="analytics" />} />

              {/* Solution Pages */}
              <Route path="/hospitality" element={<SolutionsBaseLayout pageName="hospitality" />} />
              <Route path="/healthcare" element={<SolutionsBaseLayout pageName="healthcare" />} />
              <Route path="/e-commerce" element={<SolutionsBaseLayout pageName="ecommerce" />} />
              <Route path="/fintech" element={<SolutionsBaseLayout pageName="fintech" />} />
              <Route path="/gps-tracking" element={<SolutionsBaseLayout pageName="gpsTracking" />} />
              <Route path="/real-estate" element={<SolutionsBaseLayout pageName="realEstate" />} />
              <Route path="/education" element={<SolutionsBaseLayout pageName="education" />} />
              <Route path="/erp-solutions" element={<SolutionsBaseLayout pageName="erpSolutions" />} />

              {/* Hire Team Pages */}
              <Route element={<HireTeamBaseLayout />}>
                <Route path="/hire-reactjs-developer" element={<HireTeamBaseLayout pageName="reactjs-developer" />} />
                <Route path="/hire-angularjs-developer" element={<HireTeamBaseLayout pageName="angularjs-developer" />} />
                <Route path="/hire-nodejs-developer" element={<HireTeamBaseLayout pageName="nodejs-developer" />} />
                <Route path="/hire-vuejs-developer" element={<HireTeamBaseLayout pageName="vuejs-developer" />} />
                <Route path="/hire-uiux-developer" element={<HireTeamBaseLayout pageName="uiux-developer" />} />
                <Route path="/hire-golang-developer" element={<HireTeamBaseLayout pageName="golang-developer" />} />
                <Route path="/hire-aspnet-developer" element={<HireTeamBaseLayout pageName="aspnet-developer" />} />
                <Route path="/hire-java-developer" element={<HireTeamBaseLayout pageName="java-developer" />} />
                <Route path="/hire-python-developer" element={<HireTeamBaseLayout pageName="python-developer" />} />
                <Route path="/hire-php-developer" element={<HireTeamBaseLayout pageName="php-developer" />} />
                <Route path="/hire-flutter-developer" element={<HireTeamBaseLayout pageName="flutter-developer" />} />
                <Route path="/hire-ionic-developer" element={<HireTeamBaseLayout pageName="ionic-developer" />} />
                <Route path="/hire-ios-developer" element={<HireTeamBaseLayout pageName="ios-developer" />} />
                <Route path="/hire-android-developer" element={<HireTeamBaseLayout pageName="android-developer" />} />
                <Route path="/hire-rpa-developer" element={<HireTeamBaseLayout pageName="rpa-developer" />} />
                <Route path="/hire-ml-developer" element={<HireTeamBaseLayout pageName="ml-developer" />} />
                <Route path="/hire-devops-developer" element={<HireTeamBaseLayout pageName="devops-developer" />} />
                <Route path="/hire-powerbi-developer" element={<HireTeamBaseLayout pageName="powerbi-developer" />} />
                <Route path="/hire-awscloud-developer" element={<HireTeamBaseLayout pageName="awscloud-developer" />} />
                <Route path="/hire-azurecloud-developer" element={<HireTeamBaseLayout pageName="azurecloud-developer" />} />
                <Route path="/hire-htmlcss-developer" element={<HireTeamBaseLayout pageName="htmlcss-developer" />} />
                <Route path="/hire-laravel-developer" element={<HireTeamBaseLayout pageName="laravel-developer" />} />
                <Route path="/hire-dotnetcore-developer" element={<HireTeamBaseLayout pageName="dotnetcore-developer" />} />
                <Route path="/hire-django-developer" element={<HireTeamBaseLayout pageName="django-developer" />} />
                <Route path="/hire-csharp-developer" element={<HireTeamBaseLayout pageName="csharp-developer" />} />
                <Route path="/hire-data-scientist" element={<HireTeamBaseLayout pageName="data-scientist" />} />
                <Route path="/hire-data-engineer" element={<HireTeamBaseLayout pageName="data-engineer" />} />
                <Route path="/hire-ai-developer" element={<HireTeamBaseLayout pageName="ai-developer" />} />
                <Route path="/hire-chatgpt-developer" element={<HireTeamBaseLayout pageName="chatgpt-developer" />} />
                <Route path="/hire-openai-developer" element={<HireTeamBaseLayout pageName="openai-developer" />} />
                <Route path="/hire-prompt-engineer" element={<HireTeamBaseLayout pageName="prompt-engineer" />} />
                <Route path="/hire-aiops-engineer" element={<HireTeamBaseLayout pageName="aiops-engineer" />} />
                <Route path="/hire-moodle-developer" element={<HireTeamBaseLayout pageName="moodle-developer" />} />
                <Route path="/hire-hubspot-developer" element={<HireTeamBaseLayout pageName="hubspot-developer" />} />
                <Route path="/hire-unity-developer" element={<HireTeamBaseLayout pageName="unity-developer" />} />
                <Route path="/hire-reactnative-developer" element={<HireTeamBaseLayout pageName="reactnative-developer" />} />
                <Route path="/hire-wix-developer" element={<HireTeamBaseLayout pageName="wix-developer" />} />
                <Route path="/hire-dibol-developer" element={<HireTeamBaseLayout pageName="dibol-developer" />} />
                <Route path="/hire-fortran-developer" element={<HireTeamBaseLayout pageName="fortran-developer" />} />
                <Route path="/hire-cybersecurity-developer" element={<HireTeamBaseLayout pageName="cybersecurity-developer" />} />
              </Route>

              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/portfolio" element={<Portfolio />} />

              {/* Portfolio Pages */}
              <Route element={<PortfolioBaseLayout />}>
                <Route path="/portfolio/rxnt" element={<PortfolioBaseLayout pageName="rxnt" />} />
                <Route path="/portfolio/frank" element={<PortfolioBaseLayout pageName="frank" />} />
                <Route path="/portfolio/svhs" element={<PortfolioBaseLayout pageName="svhs" />} />
                <Route path="/portfolio/tds" element={<PortfolioBaseLayout pageName="tds" />} />
                <Route path="/portfolio/tynax" element={<PortfolioBaseLayout pageName="tynax" />} />
                <Route path="/portfolio/anatomie" element={<PortfolioBaseLayout pageName="anatomie" />} />
                <Route path="/portfolio/vacsewcenter" element={<PortfolioBaseLayout pageName="vacsewcenter" />} />
                <Route path="/portfolio/wbes" element={<PortfolioBaseLayout pageName="wbes" />} />
                <Route path="/portfolio/openn" element={<PortfolioBaseLayout pageName="openn" />} />
                <Route path="/portfolio/willship" element={<PortfolioBaseLayout pageName="willship" />} />
              </Route>

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Route>

            <Route element={<ErrorPageLayout />}>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>

        </Suspense>
      </AppProvider>
    </ScrollProvider>
  );
}

export default App;
