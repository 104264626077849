import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ProductsSection = ({ title, products, productsmob }) => {
    return (
        <section className="bg-white my-4 mb-3 mb-lg-5 p-3">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs="12" className="text-center">
                        <h1 className="text-primary d-flex flex-column gap-4 mb-5 fw-bold text-center text-uppercase">
                            Types of {title} products we've developed
                        </h1>
                        <Row className="justify-content-center">
                            <Col xs="9" md="6" lg="10">
                                <div className="d-none d-lg-inline-block">
                                    <img src={products} alt="products" className="img-fluid" loading="lazy" />
                                </div>
                                <div className="d-inline-block d-lg-none">
                                    <img src={productsmob} alt="products" className="img-fluid" loading="lazy" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default ProductsSection