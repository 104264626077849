import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

import educationMockup from '../../../../../images/solutions/education.webp';
import healthcare from '../../../../../images/solutions/healthcare.webp';
import realEstate from '../../../../../images/solutions/real-estate.webp';
import fintech from '../../../../../images/solutions/fintech.webp';
import ecommerce from '../../../../../images/solutions/e-commerce.webp';
import gps from '../../../../../images/solutions/gps-track.webp';
import hospitality from '../../../../../images/solutions/hospitality.webp';
import erpSolutions from '../../../../../images/solutions/erp-solution.webp';


// Define the product data
const products = [
    { name: "healthcare", image: healthcare },
    { name: "education", image: educationMockup },
    { name: "hospitality", image: hospitality },
    { name: "ecommerce", image: ecommerce },
    { name: "fintech", image: fintech },
    { name: "gpsTracking", image: gps },
    { name: "realEstate", image: realEstate },
    { name: "erpSolutions", image: erpSolutions }
];

// Define the URL routing data
export const urlRouting = [
    {
        pathname: "/hospitality", pageName: "hospitality"
    },
    {
        pathname: "/healthcare", pageName: "healthcare"
    },
    {
        pathname: "/education", pageName: "education"
    },
    {
        pathname: "/real-estate", pageName: "realEstate"
    },
    {
        pathname: "/fintech", pageName: "fintech"
    },
    {
        pathname: "/gps-tracking", pageName: "gpsTracking"
    },
    {
        pathname: "/e-commerce", pageName: "ecommerce"
    },
    {
        pathname: "/erp-solutions", pageName: "erpSolutions"
    }
]
const RelatedProductsCarousel = ({ pageName }) => {
    const navigate = useNavigate(); // Initialize the navigate function

    // Filter out the current product from the list
    const filteredProducts = products.filter((product) => product.name !== pageName)

    // Find the corresponding URL for a given product name
    const getProductPath = (productName) => {
        const route = urlRouting.find(route => route.pageName === productName);
        return route ? route.pathname : "#";
    };

    // Slick carousel settings
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="solutions-carousel-section py-5">
            <Container>
                <h1 className="text-center mb-5 text-primary ft-wt-600 text-uppercase">Explore more</h1>
                <Slider {...settings}>
                    {filteredProducts.map((product, index) => (
                        <div className="text-center" key={index}>
                            <div className="cursor-pointer"
                                onClick={() => navigate(getProductPath(product.name))}
                            >
                                <img
                                    src={product.image}
                                    alt={product.name}
                                    className="img-fluid mx-auto"
                                />
                                <p className="mockup-title">{product.name}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </Container>
        </section>
    );
};

export default RelatedProductsCarousel;
