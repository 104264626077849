// HighlightedContent.js
import React from "react";
import { Button } from "react-bootstrap";
import { useScroll } from "../../../../context/scroll-context"; // Import the scroll context

const HighlightedContentSolutions = () => {
    const { scrollToSection, contactform } = useScroll(); // Get the scroll context

    return (
        <>
            Launch your MVP in 4 weeks.
            <Button variant="secondary"
                onClick={() => scrollToSection(contactform)}
                className=" d-block ms-auto text-uppercase mt-4"
            >
                get started today
            </Button>
        </>
    );
};

export default HighlightedContentSolutions;
