const routeMetadata = {
    '/': {
        title: 'ACube Technologies | IT Consulting Services',
        description: 'Partner with Acube Technologies, a top software development company leveraging AI for 2x faster development and precise, high-quality coding. Boost your projects with our expert team—contact us today!',
        keywords: 'IT Solutions, Mobile App Development, Web Development, UX/UI Design, technology services',
    },
    '/artificial-intelligence': {
        title: 'AI Development Services | Empower Your Business with Cutting-Edge AI',
        description: 'Leading AI development partner offering advanced AI solutions to automate business processes efficiently. Start your AI journey today – contact us now!',
        keywords: 'Artificial Intelligence, Machine Learning, Deep Learning, AI Services, AI solutions',
    },
    '/web-app': {
        title: 'Web Development Solutions | Scalable and Future-Ready Websites',
        description: 'Premier web development services from Acube Technologies to boost your digital footprint and online presence. Tailored expert solutions.',
        keywords: 'Web Development, Web Applications, Full-Stack Development, Frontend, Backend, web solutions',
    },
    '/blockchain-app': {
        title: 'Blockchain Development Services | Secure & Transparent Solutions',
        description: 'Unlock blockchain’s potential with Acube Technologies. We deliver secure, decentralized solutions to enhance business transparency.',
        keywords: 'Blockchain, Decentralized Applications, Blockchain Development, DApps, blockchain solutions',
    },
    '/cloud-strategy': {
        title: 'Cloud Services and Solutions | Future-Proof Your Business',
        description: `Accelerate business growth with Acube Technologies' IaaS, PaaS, and SaaS cloud services. Transform your business today.`,
        keywords: 'Cloud Strategy, Cloud Solutions, Cloud Infrastructure, Cloud Computing, cloud services',
    },
    '/robotic-process-automation': {
        title: 'RPA Consulting Services | Automate & Optimize Business Workflows',
        description: 'Streamline your business with AI-powered RPA consulting services. Optimize workflows with Acube Technologies’ automation specialists.',
        keywords: 'Robotic Process Automation, RPA, Workflow Automation, Business Automation, process optimization',
    },
    '/devops': {
        title: 'DevOps Consulting | Accelerate Software Delivery & Cut Cloud Costs',
        description: 'Enhance software delivery and reduce costs with Acube Technologies’ DevOps consulting services. Optimize IT efficiency today.',
        keywords: 'DevOps, CI/CD, Continuous Integration, Continuous Deployment, DevOps Services, software development',
    },
    '/uxui-services': {
        title: 'UX/UI Design Services | Crafting Engaging and Intuitive User Experiences',
        description: 'Transform digital ideas into seamless user experiences with Acube Technologies’ expert UI/UX design and development services.',
        keywords: 'UI Design, UX Design, User Experience, User Interface, Design Services, digital design',
    },
    '/mobile-app': {
        title: 'Mobile App Development | Build Next-Gen, High-Performance Apps',
        description: 'Trusted mobile app development by Acube Technologies. Custom iOS, Android, and cross-platform solutions to power your business.',
        keywords: 'Mobile App Development, iOS Development, Android Development, Mobile Solutions, app development',
    },
    '/software-testing': {
        title: 'Software Testing Services | Ensure Quality with Proven QA Solutions',
        description: 'With over a decade of experience, Acube Technologies ensures flawless software performance with expert QA and testing services.',
        keywords: 'Software Testing, QA, Quality Assurance, Performance Testing, Automated Testing, testing services',
    },
    '/analytics': {
        title: 'Business Intelligence & Analytics | Data-Driven Insights for Smarter Decisions',
        description: 'Turn data into actionable insights with Acube Technologies’ business intelligence services. Empower informed business decisions.',
        keywords: 'Data Analytics, Business Intelligence, Data Insights, Analytics Services, data solutions',
    },
    '/contact-us': {
        title: 'Get in Touch with Acube Technologies | Your Partner in Digital Solutions',
        description: 'Contact Acube Technologies today for expert consultations, support, and inquiries. Our dedicated team is ready to assist you in achieving your digital goals.',
        keywords: 'Contact, Support, Inquiries, Get In Touch, Customer Service, Digital Solutions, Expert Assistance',
    },
    '/portfolio': {
        title: 'Acube Technologies Portfolio | Showcasing Our Expertise and Success',
        description: 'Discover our impressive portfolio at Acube Technologies, featuring a wide range of successful projects across various industries. See how our innovative solutions drive results for our clients.',
        keywords: 'Portfolio, Projects, Case Studies, Work Samples, Client Success Stories, Project Highlights, Innovation, Industry Expertise',
    },

    // Hire Team pages
    '/hire-reactjs-developer': {
        title: 'Hire ReactJS Developers | Expert Talent for Dynamic Web Solutions',
        description: "Transform digital projects with Acube Technologies’ ReactJS developers. Scalable, efficient web applications tailored to your vision.",
        keywords: "hire ReactJS developer, ReactJS development company, React developers India, senior React developer, dedicated ReactJS developers, front end React developers, React development services, hire React developers India",
    },

    '/hire-angularjs-developer': {
        title: 'Hire Angular Developers | Build Scalable, Robust Web Applications',
        description: 'Hire expert Angular developers from Acube Technologies for scalable, high-performance web applications tailored to your needs.',
        keywords: 'hire AngularJS developer, AngularJS development company, hire Angular developers India, expert AngularJS developer, AngularJS web development, Angular programmers, Angular developers for hire',
    },

    '/hire-nodejs-developer': {
        title: 'Hire Node.js Developers | Scalable Web Application Development',
        description: 'Acube Technologies provides expert Node.js developers to create powerful, scalable web applications tailored to your business.',
        keywords: 'hire Node.js developer, Node.js development company, Node.js development services, hire Node.js developers India, dedicated Node.js developers, full stack Node.js developer, backend Node.js developer, Node.js outsourcing, remote Node.js developer',
    },

    '/hire-vuejs-developer': {
        title: 'Hire Vue.js Developers | High-Performance Web Applications',
        description: 'Hire Vue.js developers from Acube Technologies for advanced real-time web apps. Fast, secure frontend solutions for dynamic experiences.',
        keywords: 'hire Vue.js developer, Vue.js development services, hire Vue.js developers India, frontend Vue.js developer, Vue.js experts, Vue.js programmers, Vue.js app development, remote Vue.js developer',
    },

    '/hire-uiux-developer': {
        title: 'Hire UI/UX Designers | Create Stunning User Interfaces and Experiences',
        description: "Hire expert UI/UX designers from Acube Technologies to turn your digital vision into intuitive, engaging user experiences.",
        keywords: "hire UI/UX designer, UI/UX design company, UX design agency, hire UX designer, UI designer India, hire web designer, UI/UX design services, freelance UI/UX designer, hire remote designers",
    },

    '/hire-golang-developer': {
        title: 'Hire Golang Developers | Efficient Development for High-Performance Apps',
        description: "Hire Acube Technologies’ experienced Golang developers to build advanced, secure web applications with Go toolkits and frameworks.",
        keywords: "hire GoLang developer, GoLang development services, hire GoLang developers India, GoLang software engineer, GoLang backend developer, hire dedicated GoLang developers, freelance GoLang developer",
    },

    '/hire-aspnet-developer': {
        title: 'Hire .NET Developers | Build Enterprise-Grade Applications',
        description: "Hire expert .NET developers from Acube Technologies to build scalable, reliable applications. Elevate your projects with top-tier development.",
        keywords: "hire ASP.NET developer, .NET development company, ASP.NET development services, hire C# developer, .NET developers India, ASP.NET web development, ASP.NET freelancers, full-stack ASP.NET developer",
    },

    '/hire-java-developer': {
        title: 'Hire Java Developers | High-Performance, Scalable Solutions',
        description: 'Hire Java developers from Acube Technologies for secure, scalable solutions. Precision and expertise for every project.',
        keywords: 'hire Java developer, Java development company, Java developers India, Java backend development, full-stack Java developer, freelance Java developer, remote Java developers',
    },

    '/hire-python-developer': {
        title: 'Hire Python Developers | Secure, Scalable Business Applications',
        description: "Hire top-tier Python developers from Acube Technologies. Over 11 years of experience and 80+ experts to build scalable applications.",
        keywords: "hire Python developer, Python development company, hire Python developers India, Python web development, Django developer, Flask developer, Python full-stack developer, remote Python developer, freelance Python developer",
    },

    '/hire-php-developer': {
        title: 'Hire PHP Developers | Cost-Effective, Dynamic Web Solutions',
        description: "Acube Technologies offers skilled PHP developers for next-gen, scalable web applications. Robust solutions at a fraction of the cost.",
        keywords: "hire PHP developer, PHP development services, PHP developers India, PHP web development, dedicated PHP developer, freelance PHP developer, custom PHP development, hire PHP programmers",
    },

    '/hire-flutter-developer': {
        title: 'Hire Flutter Developers | Cross-Platform Apps That Perform',
        description: "Hire Flutter developers from Acube Technologies for cross-platform applications at $20/hr. Risk-free 15-day trial available.",
        keywords: "hire Flutter developer, Flutter app development company, Flutter developers India, cross-platform mobile app development, hire remote Flutter developer, freelance Flutter developer",
    },


    '/hire-ionic-developer': {
        title: 'Hire Ionic Developers | Hybrid Mobile App Development',
        description: 'Hire expert Ionic developers from Acube Technologies for robust hybrid mobile app development solutions.',
        keywords: 'hire Ionic developer, Ionic development services, Ionic app developer, cross-platform app development, hire remote Ionic developer, freelance Ionic developer',
    },
    '/hire-ios-developer': {
        title: 'Hire iOS Developers | Build Secure, Engaging iPhone Apps',
        description: 'Hire iOS developers from Acube Technologies for secure, high-performing iPhone apps that drive ROI for your business.',
        keywords: 'hire iOS developer, iOS app development company, iPhone app developer, iOS developers India, hire remote iOS developer, freelance iOS developer, custom iOS app development',
    },
    '/hire-android-developer': {
        title: 'Hire Android Developers | Innovative, High-Performance Mobile Apps',
        description: "Hire Android developers from Acube Technologies for innovative, high-performance mobile applications. Flexible engagement models.",
        keywords: "hire Android developer, Android app development company, Android developers India, custom Android app development, hire remote Android developer, freelance Android developer, Android app solutions",
    },
    '/hire-rpa-developer': {
        title: 'Hire RPA Developers | Streamline Processes with Automation',
        description: 'Automate business processes with Acube Technologies’ RPA developers. Enhance productivity and reduce errors with experienced engineers.',
        keywords: 'hire RPA developer, robotic process automation, RPA development services, hire RPA consultant, RPA developers in India, workflow automation, business automation solutions, freelance RPA developer',
    },
    '/hire-ml-developer': {
        title: 'Hire Machine Learning Engineers | Unlock Advanced Data Insights',
        description: "Hire machine learning developers from Acube Technologies for top-tier expertise and cost savings on your AI projects.",
        keywords: "hire machine learning engineer, hire ML engineer, hire AI ML developers, hire AI and machine learning engineer, hire AI ML developer, hire artificial intelligence and machine learning developer, AI developers for hire, AI and ML developers for hire, hire AI machine learning developer, hire ML engineer, hire AI ML software engineer, hire ML developers, freelance machine learning developer, hire dedicated machine learning developers, hire dedicated ML developers, hire machine learning app developer, hire machine learning web developers, machine learning engineering services, machine learning engineers for hire, hire ML engineers, hire machine learning mobile app developer, hire a machine learning engineer, hire dedicated machine learning developer, hire machine learning app developers",
    },
    '/hire-devops-developer': {
        title: 'Hire DevOps Engineers | Streamline Your Development with Automation',
        description: "Hire DevOps engineers from Acube Technologies for tailored software delivery and infrastructure optimization solutions.",
        keywords: "hire DevOps engineer, DevOps engineer, DevOps developer, DevOps consulting, DevOps company, DevOps service providers, DevOps maturity model, DevOps engineer roadmap, Salesforce DevOps, DevOps strategy, hire DevOps expert, hire a DevOps engineer, freelancer DevOps engineer, hire Azure DevOps engineer, hire Azure DevOps engineers, hire CI/CD engineers, DevOps experts, Azure DevOps engineer, Microsoft certified DevOps engineer expert, Azure DevOps expert, Azure DevOps engineer expert, certified DevOps engineer, Azure DevOps specialist, DevOps engineer expert, Microsoft certified DevOps engineer, Microsoft DevOps engineer, DevOps engineer Microsoft, DevOps expert Azure",
    },
    '/hire-powerbi-developer': {
        title: 'Hire Power BI Consultants | Transform Data into Actionable Insights',
        description: "Hire Power BI consultants from Acube Technologies for comprehensive data visualization and actionable insights.",
        keywords: "Power BI consulting, Power BI consulting services, Power BI consulting company, hire Power BI, hire Power BI developer, hire Power BI experts, Power BI consultant near me, hire Microsoft BI developer, hire Power BI service, Microsoft Power BI developer near me, Microsoft Power BI consultant, MS Power BI developer near me, Microsoft Power BI developer, hire business intelligence consultant, Power BI specialist hire, hire BI consulting, Power BI developer hire, hire Power BI developer, Power BI developer freelance, freelance Power BI developer, hire dedicated Power BI developer, hire Tableau BI developer",
    },
    '/hire-awscloud-developer': {
        title: 'Hire AWS Developers | Scalable, Cloud-Native Solutions for Your Business',
        description: 'Hire AWS developers from Acube Technologies to build, deploy, and manage cloud solutions. Expert cloud architecture and services.',
        keywords: 'hire AWS cloud developer, AWS cloud services, cloud infrastructure development, AWS consulting, AWS developers in India, hire remote AWS developers, AWS cloud engineer, freelance AWS developer',
    },
    '/hire-azurecloud-developer': {
        title: 'Hire Azure Developers | Expert Cloud Solutions for Maximum Performance',
        description: 'Hire Azure developers from Acube Technologies for unmatched expertise in Azure cloud solutions and support.',
        keywords: 'hire Azure cloud developer, Azure cloud services, Azure infrastructure development, hire Azure consultants, cloud development with Azure, Azure cloud developers in India, remote Azure developers, freelance Azure developer',
    },
    '/hire-htmlcss-developer': {
        title: 'Hire HTML/CSS Developers | Responsive & Visually Stunning Websites',
        description: 'Create visually stunning, responsive websites with Acube Technologies’ HTML/CSS developers. Enhance your online presence today.',
        keywords: 'hire HTML developer, hire CSS developer, HTML/CSS development services, frontend web development, responsive web design, HTML5 developer, CSS3 developer, freelance HTML/CSS developer, hire remote front-end developers',
    },
    '/hire-django-developer': {
        title: 'Hire Django Developers | Build Robust, High-Performance Web Applications',
        description: 'Hire Django developers from Acube Technologies to bring your web project to life. Affordable, expert solutions within 48 hours.',
        keywords: 'hire Django developer, Django development company, Python Django developers, backend development with Django, full-stack Django development, hire remote Django developers, freelance Django developer, Django web development services',
    },
    '/hire-csharp-developer': {
        title: 'Hire C# Developers | Custom Enterprise Solutions Tailored to Your Needs',
        description: "Hire C# developers from Acube Technologies for custom enterprise applications. Top-quality solutions across the .NET ecosystem.",
        keywords: "C# developer, hire C# developers, hire C# dedicated developers, hire C# developers in India, hire C# software developer, hire C# programmer, hire software developer India, C# web development company, freelance C# developer, C# app development, C# development outsourcing, C# mobile app development, C# web programming, AWS Lambda for .NET C# developers, API development C#, web development using C#, app development in C#, C# development company, C# development services, C# software development company, C# application development company, C# for mobile app development, C# .NET development, C# .NET web development, C# outsourcing",
    },
    '/hire-laravel-developer': {
        title: 'Hire Expert Laravel Developers | Build Scalable Web Applications',
        description: 'Hire expert Laravel developers from Acube Technologies to create robust and scalable PHP web applications. Our team offers full-stack development and custom backend solutions tailored to your business needs.',
        keywords: 'hire Laravel developer, Laravel development company, PHP Laravel development, hire remote Laravel developer, freelance Laravel developer, full-stack Laravel development, Laravel web development',
    },

    '/hire-dotnetcore-developer': {
        title: 'Hire .NET Core Developers | Reliable Cross-Platform Development',
        description: 'Hire Acube Technologies’ .NET Core developers for high-performance, robust applications at affordable, transparent pricing.',
        keywords: 'hire .NET Core developer, .NET Core development company, hire .NET Core developers in India, cross-platform .NET Core development, enterprise software development, full-stack .NET Core developer, remote .NET Core developer',
    },
    '/hire-data-scientist': {
        title: 'Hire Data Scientists | Data-Driven Solutions for Smarter Decisions',
        description: 'Unlock the power of your data with Acube Technologies’ data scientists. Gain valuable insights and adopt a data-driven approach.',
        keywords: 'hire data scientist, data science services, machine learning solutions, big data analytics, data scientists for hire, hire remote data scientist, freelance data scientist, data engineering, advanced analytics',
    },
    '/hire-data-engineer': {
        title: 'Hire Data Engineers | Build Robust Data Pipelines for Your Business',
        description: 'Hire expert data engineers from Acube Technologies for seamless data management and valuable business insights.',
        keywords: 'hire data engineer, data pipeline development, big data engineering, data infrastructure, cloud data engineer, hire remote data engineers, freelance data engineer, data architect, data engineering services',
    },
    '/hire-ai-developer': {
        title: 'Hire AI Developers | Build Advanced AI Solutions',
        description: 'Hire Acube Technologies’ AI developers to build advanced AI solutions. Faster delivery, innovative AI systems with our expert team.',
        keywords: 'hire AI developer, artificial intelligence development, machine learning developer, AI software development, NLP developer, computer vision development, hire remote AI developer, AI experts for hire, freelance AI developer',
    },
    '/hire-chatgpt-developer': {
        title: 'Hire ChatGPT Developers | Integrate AI-Powered Conversations',
        description: "Hire ChatGPT developers from Acube Technologies for AI-driven applications. Tailored, powerful solutions with ChatGPT integration.",
        keywords: "hire ChatGPT developers, hire software developer for ChatGPT development, hire ChatGPT dedicated developers, ChatGPT developer hire, hire ChatGPT programmer, hire AI developers, hire ChatGPT software development team, AI developers for hire, hire ChatGPT developers in India, hire ChatGPT developer team, AI software development team, hire offshore ChatGPT developers, AI developer for hire, hire app developer for ChatGPT, hire ChatGPT team of developers, hire ChatGPT coders, ChatGPT developers in India, hire ChatGPT developer for a project, offshore developers for ChatGPT, AI software developers, hire someone to make an app using ChatGPT",
    },
    '/hire-openai-developer': {
        title: 'Hire OpenAI Developers | Expert Engineers for Innovative AI Solutions',
        description: "Acube Technologies’ OpenAI developers build advanced AI applications with OpenAI technology. Stay ahead with cutting-edge AI solutions.",
        keywords: "hire OpenAI developers, hire software developer for OpenAI, hire OpenAI API developer, hire OpenAI specialists, hire OpenAI development team, hire OpenAI experts, hire OpenAI API integration developers, hire AI developers, hire OpenAI chatbot developers, OpenAI software development services, freelance OpenAI developers, hire OpenAI developer for a project, OpenAI integration services, AI software developers, OpenAI consultants for hire, AI development with OpenAI, hire dedicated OpenAI developers, OpenAI developers in India, hire OpenAI engineer, hire remote OpenAI developers, hire offshore OpenAI developers",
    },
    '/hire-prompt-engineer': {
        title: 'Hire Prompt Engineers | Create Cutting-Edge AI-Powered Solutions',
        description: "Hire prompt engineers from Acube Technologies to optimize GPT-4, DALL-E, and more. Top-notch services for driving innovation.",
        keywords: "hire AI prompt engineer, prompt engineering for hire, hire AI prompt engineering, hire prompt engineering AI, hire AI developers, hire ChatGPT prompt engineering, AI developers for hire, hire AI engineer, hire AI developer, hire machine learning engineer, hire AI software engineer, hire AI software developer, hire prompt engineering ChatGPT, hire AI software development team, artificial intelligence developers near me, hire AI ML team, hire ML engineer near me, hire AI and machine learning team, artificial intelligence engineering for hire, hire prompt engineering, hire OpenAI prompt engineering, hire OpenAI prompt engineer, hire LLM prompt engineering",
    },
    '/hire-aiops-engineer': {
        title: 'Hire AIOps Engineers | Optimize Operations with AI Automation',
        description: "Lead your AI transformation with Acube Technologies’ AIops engineers. Fast, AI-driven solutions from our experienced team.",
        keywords: "hire AIOps engineer, AIOps development services, AI-driven IT operations, AIOps automation, hire AI engineers, AIOps for IT automation, AIOps for incident management, AIOps experts, AIOps engineers for hire, IT automation with AIOps, AI in IT operations",
    },
    '/hire-moodle-developer': {
        title: 'Hire Moodle Developers | Custom E-Learning Solutions',
        description: "Hire Moodle developers from Acube Technologies to build custom e-learning platforms. Affordable, reliable solutions for your educational needs.",
        keywords: "Moodle experts for hire, hire Moodle developer, Moodle mobile app development, Moodle app development, Moodle mobile development, Moodle module development, find Moodle expert, hire Moodle expert, Moodle LMS developer, PHP Moodle developer",
    },
    '/hire-reactnative-developer': {
        title: 'Hire React Native Developers | Build Cross-Platform Mobile Apps',
        description: 'Hire React Native developers from Acube Technologies for high-performance mobile apps with cross-platform compatibility.',
        keywords: 'hire React Native developer, React Native development services, React Native developers in India, cross-platform app development, mobile app development, React Native app developer, freelance React Native developer, hire remote React Native developer',
    },
    '/hire-unity-developer': {
        title: 'Hire Unity Developers | Elevate Your Game Development with Unity3D',
        description: 'Hire Unity3D developers from Acube Technologies for expert game development solutions. Elevate your gaming projects.',
        keywords: 'hire Unity developer, Unity development services, Unity developers in India, game development, AR/VR development, cross-platform development, Unity game developer, hire remote Unity developer, freelance Unity developer',
    },
    '/hire-hubspot-developer': {
        title: 'Hire HubSpot Developers | Optimize and Build Powerful CMS Solutions',
        description: 'Hire HubSpot developers from Acube Technologies for website customization, development, and optimization services.',
        keywords: 'hire HubSpot developer, HubSpot development services, HubSpot CRM development, marketing automation, HubSpot integration, HubSpot website design, HubSpot certified developers, hire remote HubSpot developer, freelance HubSpot developer',
    },



    //portfolio pages
    '/portfolio/rxnt': {
        title: 'RXNT - Comprehensive Healthcare Management Software',
        description: 'RXNT offers all-in-one healthcare management solutions, including EHR, medical billing, and appointment scheduling, empowering healthcare providers to enhance patient care and streamline practice operations.',
        keywords: 'RXNT, healthcare management software, electronic health records, medical billing solutions, healthcare technology, appointment scheduling platform',
    },
    '/portfolio/frank': {
        title: 'Frank - Innovative Food Safety Compliance & Management App',
        description: 'Frank is a food safety compliance app that ensures adherence to regulations, improves operational efficiency, and helps businesses maintain high food safety standards.',
        keywords: 'Frank, food safety compliance, food safety app, compliance management, operational efficiency, food safety software',
    },
    '/portfolio/svhs': {
        title: 'SVHS - Advanced School Management & Administration System',
        description: 'SVHS is a school management system designed to simplify administration, enhance communication, and improve collaboration between educators, students, and parents.',
        keywords: 'SVHS, school management software, education administration solutions, school administration, educational technology, communication tools for schools',
    },
    '/portfolio/tds': {
        title: 'TDS - Leading Web-Based Global Tax API Solution',
        description: 'TDS provides a reliable tax API for eCommerce platforms, offering comprehensive indirect tax content across 14,500 jurisdictions globally. Acquired by Thomson Reuters in 2014.',
        keywords: 'TDS, tax API, global tax solutions, indirect tax software, eCommerce tax compliance, Thomson Reuters tax API, tax jurisdictions',
    },
    '/portfolio/tynax': {
        title: 'Tynax - Leading Technology Brokerage & IP Marketplace',
        description: 'Tynax is a leading technology brokerage platform, facilitating patent transactions and intellectual property sales, connecting innovators with businesses seeking cutting-edge technologies.',
        keywords: 'Tynax, technology brokerage platform, patent transactions, intellectual property marketplace, IP sales, innovation platform',
    },
    '/portfolio/anatomie': {
        title: 'Anatomie - Luxury Travel Fashion for Modern Lifestyle',
        description: 'Anatomie is a luxury fashion brand that blends style and comfort in travel and lifestyle apparel, offering high-end clothing designed for the modern, sophisticated traveler.',
        keywords: 'Anatomie, luxury travel fashion, lifestyle clothing, high-end fashion, designer travel apparel, luxury apparel brand',
    },
    '/portfolio/vacsewcenter': {
        title: 'Vac Sew Center - Expert Sewing Machine Repair & Products',
        description: 'Vac Sew Center provides professional sewing machine repair services and offers a wide range of quality sewing products and accessories for hobbyists and professionals.',
        keywords: 'Vac Sew Center, sewing machine repair services, sewing products, quilting supplies, sewing accessories, sewing machine services',
    },
    '/portfolio/wbes': {
        title: 'WBES - Flexible Office Space & Virtual Office Solutions',
        description: 'West Boca Executive Suites (WBES) provides flexible office space solutions including private offices, virtual offices, and meeting rooms, tailored to meet the needs of businesses in Boca Raton.',
        keywords: 'WBES, flexible office space, virtual offices, private office rental, meeting rooms, business suites, office space Boca Raton',
    },
    '/portfolio/openn': {
        title: 'Openn - Transparent Online Auction & Bidding Platform',
        description: 'Openn is a leading online auction platform providing transparent, competitive bidding processes that ensure fair pricing for buyers and sellers alike.',
        keywords: 'Openn, online auction platform, transparent bidding, competitive auctions, e-auction software, online bidding solutions',
    },
    '/portfolio/willship': {
        title: 'Willship - Global Vehicle Shipping & Transport Solutions',
        description: 'Willship offers trusted global vehicle transport services, ensuring safe and timely shipping of cars and other vehicles worldwide with seamless logistics solutions.',
        keywords: 'Willship, global vehicle shipping, car transport services, vehicle logistics, international vehicle shipping, auto transport solutions',
    },


    //solutions page

    '/hospitality': {
        title: 'Hospitality IT Solutions | Streamline Operations with Innovation',
        description: 'Cloud-based hospitality solutions from Acube Technologies. Streamline HMS, PMS, RMS, and event management for your business.',
        keywords: 'hospitality IT solutions, restaurant management software, POS systems, online booking tools, hospitality technology',
    },
    '/healthcare': {
        title: 'Healthcare IT Solutions | Transform Care & Operations with Technology',
        description: 'Streamline patient care and operations with Acube Technologies’ healthcare IT services. Boost efficiency in the healthcare sector.',
        keywords: 'healthcare IT, telemedicine solutions, electronic health records, patient management systems, healthcare software',
    },
    '/e-commerce': {
        title: 'E-commerce Solutions | Accelerate Growth with Powerful Platforms',
        description: 'High-performance, secure e-commerce platforms from Acube Technologies. Enhance customer experience and grow your online business.',
        keywords: 'e-commerce solutions, online store development, payment gateway integration, product management systems, e-commerce software',
    },
    '/fintech': {
        title: 'Fintech Software Development | Driving Innovation in Financial Services',
        description: `Stay competitive with Acube Technologies' custom fintech software solutions. Tailored applications for your unique business needs.`,
        keywords: 'fintech solutions, mobile payments, online banking services, financial management software, financial technology',
    },
    '/real-estate': {
        title: 'Real Estate Software Development | Custom Solutions for a Competitive Edge',
        description: 'Bespoke real estate software development from Acube Technologies. Customized platforms to optimize real estate operations.',
        keywords: 'real estate IT solutions, property management software, online property listings, real estate analytics, real estate software',
    },
    '/gps-tracking': {
        title: 'GPS Tracking Solutions | Tailored Location Solutions for Key Industries',
        description: 'Achieve operational efficiency with Acube Technologies’ customized GPS tracking solutions for OEM, construction, and agriculture industries.',
        keywords: 'GPS tracking solutions, location-based applications, fleet management software, asset tracking, logistics technology',
    },
    '/education': {
        title: 'IT Solutions for Education | Empowering Institutions with Technology',
        description: 'Innovative IT solutions for education by Acube Technologies. Ensure seamless learning and operational excellence.',
        keywords: 'e-learning solutions, education technology, learning management systems, virtual classrooms, online course development',
    }



};

export default routeMetadata;
